<template>
  <div>
    <MiniBanner />
    <div class="txt txt1">
      <img
          src="@/assets/mobile/HYC_mobile.jpg"
          alt=""
        />
    </div>
  </div>
</template>

<script>
import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";

export default {
  name: "MiniInvitationLetter",
  components: {
    MiniBanner,
  },
  methods: {
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("主席致辞");
  },
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  /* padding: 25px 30px; */
}
.txt img {
  max-width: 100%;
}
</style>